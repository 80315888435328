<template>
  <div class="qian-box">
    <h1>前端</h1>
  </div>
</template>

<script>
export default {
    name:'Qian',
    data(){
        return{

        }
    }
}
</script>

<style scoped>
.qian-box{
    width: 300px;
    height: 3000px;
    background-color: green;
}
</style>