<template>
  <div id="app">
      <div class="app-top">
        <div><router-link to="/Qian" @click.native="choose(1)">前端</router-link><span v-if="choosenum==1" :style="[{'width':spanwidth+'px'},{'opacity':spanopacity}]"></span></div>
        <div><router-link to="/Hou" @click.native="choose(2)">后端</router-link><span v-if="choosenum==2" :style="[{'width':spanwidth+'px'},{'opacity':spanopacity}]"></span></div>
        <div><router-link to="/Note" @click.native="choose(3)">笔记</router-link><span v-if="choosenum==3" :style="[{'width':spanwidth+'px'},{'opacity':spanopacity}]"></span></div>
        <div><router-link to="/Notice" @click.native="choose(4)">公告</router-link><span v-if="choosenum==4" :style="[{'width':spanwidth+'px'},{'opacity':spanopacity}]"></span></div>
    </div>
    <div class="app-center">
        <router-view></router-view>
    </div>
    <Suspension ref="zk" :showlogin="showlogin" :goding="goding"></Suspension>
    <Login v-if="loginshow==true" :showlogin="showlogin" :closelogin="closelogin"></Login>
  </div>
</template>

<script>
import Suspension from "./components/Suspension.vue";
import Login from "./components/Login.vue";

export default {
  name: 'App',
  data(){
    return{
      choosenum:0,
      spanwidth:0,
      spanopacity:0,
      loginshow:false,
    }
  },
  components: {
    Suspension,Login
  },
  mounted(){},
  methods:{
    choose(count){
      this.choosenum=count;
    },
    showlogin(){
      if(this.$store.state.load==false){
        this.loginshow=true;
      }
    },
    closelogin(){
      this.loginshow=false;
    },
    goding(){
      document.querySelector('.app-center').scrollTop=0;
    }
  },
  // props:{
  //       closelogin:{
  //           type: Function,
  //           required: true,
  //       }
  // },
  watch:{
    choosenum:{
      immediate: true,
      deep: true,
      handler(newName, oldName) {
        this.spanwidth=0;
        this.spanopacity=0;
        var a=setInterval(()=>{
            this.spanwidth+=8;
            this.spanopacity+=0.2;
            if(this.spanwidth>=40){  
                this.spanwidth=40;
                this.spanopacity=1;
                clearInterval(a)
            }
        }, 50);
      }
    }
  }
}
</script>

<style>

router-view{
  transition: all 0.5s;
}
::-webkit-scrollbar {width: 0 !important}
*{
  margin: 0;
  padding: 0;
  
}
#app {

}
.app-top{
  width: 100%;
  height: 60px;
  background-color: #322c2d;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.app-top div{
  width: 100px;
  height: 30px;
  /* background-color: skyblue; */
  margin: 15px 15px 0;
  text-align: center;
  line-height: 30px;
  font-size: 22px;
}
.app-top div a{
  width: 100%;
  height: 30px;
  display: block;
}
.app-top div span{
  position: relative;
  top: -8px;
  display: inline-block;
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background-color: white;
}
.app-center{
  width: 100%;
  height: 877px;
  background-color: skyblue;
  overflow-y: scroll;
  overflow-x: hidden;
}
/*去除a标签下划线*/
a {
	text-decoration: none;
}
/*去除未被访问的a标签的下划线*/
a:link {
	text-decoration: none;
}
/*去除已经被访问过的a标签的下划线*/
a:visited {
  color: white;
	text-decoration: none;
}
/*去除鼠标悬停时的a标签的下划线*/
a:hover {
	text-decoration: none;
}
/*去除正在点击的a标签的下划线（鼠标按下，尚未松开）*/
a:active {
	text-decoration: none;
}
/*去除获得焦点的a标签的下划线（被鼠标点击过）*/
a:focus {
	text-decoration: none;
}
</style>
