<template>
  <div>
      暂时还没有笔记发布
  </div>
</template>

<script>
export default {
    name:'Note',
    data(){
        return{

        }
    }
}
</script>

<style scoped>

</style>