<template>
  <div class="Suspension-box" @click="change" :style="[{'width':boxwidth+'px'},{'border-radius':borderradius+'px'}]">
    <div class="smallyuan" :style="[{'opacity':boxopacity}]" v-if="usershow==true">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="userinfor" :style="{'margin':imgmargin}">
        <img :src="imgs" alt="">
        <div class="userinfor-name" :style="[{'opacity':boxopacity}]" v-if="usershow==true" @click.stop="login">{{$store.state.user.nickname}}</div>
    </div>
    <span class="fenge" :style="[{'opacity':boxopacity}]" v-if="usershow==true&&$store.state.load==true"></span>
    <div class="person" :style="[{'opacity':boxopacity}]" v-if="usershow==true&&$store.state.load==true">
        <div class="smalltitle-one">个人账户</div>
        <div class="smalltitle-two">
            <div>个人空间<span class="hoverleft"></span></div>
            <div @click.stop="todiary">个人日记<span class="hoverleft"></span></div>
            <div>我的相册<span class="hoverleft"></span></div>
            <div>私信留言<span class="hoverleft"></span></div>
            <div>消息通知<span class="hoverleft"></span></div>
            <div @click.stop="set">账号设置<span class="hoverleft"></span></div>
            <div @click.stop="outlogin">退出登录<span class="hoverleft"></span></div>
        </div>
    </div>
    <span class="fenge" :style="[{'opacity':boxopacity}]" v-if="usershow==true"></span>
    <div class="person" :style="[{'opacity':boxopacity}]" v-if="usershow==true">
        <div class="smalltitle-one">快捷功能</div>
        <div class="smalltitle-two">
            <div @click.stop="ding">回到顶部<span class="hoverleft"></span></div>
            <div>问题反馈<span class="hoverleft"></span></div>
            <div>关于本站<span class="hoverleft"></span></div>
        </div>
    </div>
  </div>
</template>

<script>
import vmson from "./emptyVue"
export default {
    
    name:'Suspension',
    data(){
        return{
            imgs:this.$store.state.user.img,
            boxwidth:265,
            borderradius:25,
            boxopacity:1,
            usershow:true,
            imgmargin:'20px 30px 10px',
        }
    },
    mounted(){
        var that=this;
        vmson.$on("setimg",(val)=>{//监听事件aevent，回调函数要使用箭头函数;
            console.log(val);//打印结果：我是a组件的数据
            that.setimg(val);
        })
        that.setimg(' ');
    },
    methods:{
        setimg(a){
            if(a==' '){
                this.imgs='https://hctoyc.com/bokephp/touxiang/crode.jpg?res='+new Date().getTime();
            }else{
                this.imgs='https://hctoyc.com/bokephp/touxiang/'+a+'.jpg?res='+new Date().getTime();
            }
        },
        set(){
            var that=this;
            that.$axios.get('https://hctoyc.com/bokephp/set/getset.php',{params:{nickname:that.$store.state.user.nickname}}).then(
                (response)=>{
                    that.$store.state.user.nickname=response.data.nickname;
                    that.$store.state.user.phone=response.data.phone;
                    that.$store.state.user.email=response.data.email;
                    that.$router.push('/UserSet');
                },
                (err)=>{

                }
            )
        },
        todiary(){
            this.$router.push('/Diary');
        },
        login(){
            if(this.$store.state.load==true){
                this.$message({
                    message: '已登录',
                    type: 'success'
                });
            }else{
                this.showlogin();
            }
        },
        outlogin(){
            this.$confirm('确定要退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('qxlogin');
                this.$router.push('/Qian');
                this.$message({
                    type: 'success',
                    message: '退出成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消退出'
                });          
            });
        },
        change(){
            if(this.boxwidth==70){
                var a=setInterval(()=>{//都是15次变化结束
                    this.imgmargin='10px 30px 0';
                    this.usershow=true
                    this.boxwidth+=13;
                    this.borderradius-=0.66;
                    this.boxopacity+=0.066;
                    if(this.boxwidth>=265){  
                        this.boxwidth=265;
                        this.borderradius=25;
                        this.boxopacity=1;
                        
                        clearInterval(a)
                    }
                }, 20);
            }else if(this.boxwidth==265){
                var a=setInterval(()=>{
                    this.boxwidth-=13;
                    this.borderradius+=0.66;
                    this.boxopacity-=0.066;
                    if(this.boxwidth<=70){  
                        this.boxwidth=70;
                        this.borderradius=35;
                        this.boxopacity=0;
                        this.usershow=false;
                        this.imgmargin='0';
                        clearInterval(a)
                    }
                }, 20);
            }
        },
        ding(){
            this.goding();
        }
    },
    props:{
        showlogin:{
            type: Function,
            required: true,
        },
        goding:{
            type: Function,
            required: true,
        },
    },
}
</script>

<style scoped>
.Suspension-box{
    padding: 3px;
    transition: all 0.3s;
    position: fixed;
    right: 20px;
    top: 100px;
    background-color: rgba(0, 0, 0, 0.7);
    overflow: hidden;
}
.smallyuan{
    width: 205px;
    height: 30px;
    margin: 20px 30px 10px;
}
.smallyuan span{
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 15px;
}
.smallyuan span:nth-child(1){
    background-color: #eb5a56;
}
.smallyuan span:nth-child(2){
    background-color: #f8bc33;
}
.smallyuan span:nth-child(3){
    background-color: #62cb44;
}
.userinfor{
    position: relative;
    width: 205px;
    height: 70px;
}
.userinfor img{
    transition: all 0.3s;
    border-radius: 35px;
    width: 70px;
    height: 70px;
    margin-right: 15px;
}
.userinfor-name{
    position: absolute;
    top: 0px;
    display: inline-block;
    width: 120px;
    height: 70px;
    color: white;
    line-height: 70px;
    font-size: 26px;
    letter-spacing: 1px;
}
.fenge{
    display: block;
    width: 205px;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 20px 30px;
}
.person{
    width: 205px;
    margin: 10px 30px;
    color: rgba(255, 255, 255, 0.9);
}
.smalltitle-one{
    font-size: 20px;
    height: 30px;
    line-height: 30px;
    width: 100%;
    letter-spacing: 2px;
    padding-bottom: 10px;
}
.smalltitle-two{
    position: relative;
    width: 100%;
}
.smalltitle-two div{
    position: relative;
    width: 160px;
    height: 50px;
    line-height: 50px;
    padding-left: 45px;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 300;
    transition: all 0.3s;
    border-radius: 7px;
}
.hoverleft{
    transition: all 0.3s;
    display: inline-block;
    width: 5px;
    height: 40px;
    position: absolute;
    left: -30px;
    top: 5px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.smalltitle-two div:hover{
    background-color: rgba(255, 255, 255, 0.3);
}
.smalltitle-two div:hover .hoverleft{
    background-color: rgba(255, 255, 255, 1);
}
</style>