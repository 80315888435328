import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)
//用于相应组件中的动作
const actions={
    qxlogin(context){
        context.commit('QXLOGIN')
    },
    changeimg(context,a){
        context.commit('CHANGEIMG',a)
    }
}
//用于操作数据
const mutations={
    QXLOGIN(state){
        state.user={

            img:'../assets/crode.jpg',
        };
        state.load=false;
    },
    CHANGEIMG(state,a){
        console.log('改头像',a);
        state.user.img='';
        state.user.img='https://hctoyc.com/bokephp/touxiang/'+a+'.jpg?res='+new Date().getTime()
    }
}
//用于存储数据
const state={
    load:false,//暂时修改为true
    user:{
        nickname:'未登录',
        phone:' ',
        email:' '
    },
    // user:{
    //     nickname:'何厚聪',
    //     phone:'13530390221',
    //     email:'2415994048@qq.com'
    // },
}

export default new Vuex.Store({
    actions,
    mutations,
    state,
})

