<template>
  <div class="login-box" :style="{'opacity':boxopacity}">
    <div class="nrbox" v-loading="loading">
        <span @click="close()"><el-icon class="el-icon-circle-close icon-close"></el-icon></span>
        <el-card class="zhuce" style="border-radius:25px;" v-if="loginshow==true">
            <div class="login-title">欢迎使用</div>
            <div class="zhucesr">
                <el-form :model="user" :rules="ruleuser" ref="user">
                    <div class="infor-name">
                        <div class="login-name">
                            <el-form-item prop="zhanghao">
                                <el-input v-model="user.zhanghao" placeholder="邮箱 / 手机号"></el-input>
                            </el-form-item>
                        </div>
                        <div class="login-name">
                            <el-form-item prop="password">
                                <el-input v-model="user.password" placeholder="请输入密码" show-password></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="zhuce-check">
                        <el-checkbox v-model="autocheck">自动登录</el-checkbox><span style="font-size: 14px;float:right;color:#398dee;">忘记密码?</span>
                    </div>
                    <div class="zhuce-next">
                        <el-button class="login-button" type="primary" :loading="loginload==1" @click="gologin">登录</el-button>
                        <el-button class="login-button" style="float:right;" @click="tozhuce">注册</el-button><br>
                        <el-checkbox v-model="logincheck">我已阅读、同意协议<span style="color:#398dee;">《服务条款》、《隐私协议》</span></el-checkbox>
                    </div>
                </el-form>
            </div>
        </el-card>
        <el-card class="zhuce" v-if="loginshow==false">
            <div class="buzhou-box">
                <div class="buzhou">
                    <div :style="{border:buzhou==1?'2px solid #398dee':'2px solid #dadada'}"><span :class="{'active': buzhou == 1}">1</span></div>
                    <div><span :class="{'active': buzhou == 1}">填写基本信息</span></div>
                </div>
                <div class="buzhou" :class="{'active': buzhou == 2}">
                    <div :style="{border:buzhou==2?'2px solid #398dee':'2px solid #dadada'}"><span :class="{'active': buzhou == 2}">2</span></div>
                    <div><span :class="{'active': buzhou == 2}">设置密码</span></div>
                </div>
                <div class="buzhou" :class="{'active': buzhou == 3}">
                    <div :style="{border:buzhou==3?'2px solid #398dee':'2px solid #dadada'}"><span :class="{'active': buzhou == 3}">3</span></div>
                    <div><span :class="{'active': buzhou == 3}">完成注册</span></div>
                </div>
            </div>
            <div class="zhucesr">
                <el-form :model="zhuce" :rules="rules" ref="zhuce">
                <div class="infor-name" :style="[{'left':inforleft+'px'},{'opacity':inforopacity}]" v-if="buzhou==1">
                    <div class="login-name">
                        <el-form-item prop="nickname">
                            <el-input v-model="zhuce.nickname" placeholder="请输入昵称"></el-input>
                        </el-form-item></div>
                    <div class="login-name">
                        <el-form-item prop="phone">
                            <el-input v-model="zhuce.phone" placeholder="请输入电话"></el-input>
                        </el-form-item>
                    </div>
                    <div class="login-name">
                        <el-form-item prop="email">
                            <el-input v-model="zhuce.email" placeholder="请输入邮箱"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="infor-name" :style="[{'left':inforleft+'px'},{'opacity':inforopacity}]" v-if="buzhou==2">
                    <div class="login-name">
                        <el-form-item prop="password">
                            <el-input v-model="zhuce.password" placeholder="请输入密码" show-password></el-input>
                        </el-form-item>
                    </div>
                    <div class="login-name">
                        <el-form-item prop="checkpassword">
                            <el-input v-model="zhuce.checkpassword" placeholder="请确认密码" show-password></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="infor-name" :style="[{'left':inforleft+'px'},{'opacity':inforopacity}]" v-if="buzhou==3">
                    <div class="zhucefinish">
                        <div><i class="el-icon-circle-check"></i></div>
                        <div>恭喜你,注册成功!</div>
                    </div>
                </div>
                <div class="zhuce-check">
                    <el-checkbox :style="{'opacity':xieyiopacity}" v-model="zhucecheck">我已阅读、同意协议<span style="color:#398dee;">《服务条款》、《隐私协议》</span></el-checkbox>
                </div>
                <div class="zhuce-next">
                    <el-button class="zhuce-button" type="primary" @click="next" :loading="loginload==2" disabled>{{nexttxt}}</el-button><br>
                    <span :style="{'opacity':xieyiopacity}" style="height:40px;line-height:40px;">已有账号?<span style="font-size: 14px;color:#398dee;" @click="tologin">去登录</span></span>
                </div>
                </el-form>
            </div>
        </el-card>
        
    </div>
  </div>
</template>

<script>
import vmson from "./emptyVue"
export default {
    name:'Login',
    data(){
		var checkPhone = (rule, value, callback) => {
		    const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
		    if (!value) {
		      return callback(new Error('电话号码不能为空'))
		    }else if (!Number.isInteger(+value)) {
		        callback(new Error('请输入数字值'))
		    } else if (phoneReg.test(value)) {
		        callback()
		    } else {
		        callback(new Error('电话号码格式不正确'))
		    }
		    
		}
        var checkPass = (rule, value, callback) => {
		    if (value === '') {
                callback(new Error('请再次输入密码'))
            } else if (value !== this.zhuce.password) {
                callback(new Error('两次输入密码不一致!'))
            } else {
                this.disablednext=true;
                callback()
            }
		}
        return{
            boxopacity:0,
            loading:false,
            logincheck:false,
            zhucecheck:false,
            autocheck:false,
            loginshow:true,
            loginload:0,
            zhuceload:false,
            disablednext:false,
            inforleft:0,
            inforopacity:1,
            xieyiopacity:1,
            buzhou:1,
            nexttxt:'下一步',
            user:{
                zhanghao:'',
                password:'',
            },
            zhuce:{
                nickname:'',
                phone:'',
                email:'',
                password:'',
                checkpassword:'',
            },
            yzna:false,
            yzph:false,
            yzem:false,
            yzpa:false,
            rules: {
                nickname: [
                    { required: true, message: '昵称不能为空', trigger: 'blur' },
                    // { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' },
                    // { required: true, pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/, message: '姓名不支持特殊字符', trigger: 'blur'}
                ],
                phone: [
                    { required: true, message: '手机号不能为空', trigger: 'blur' },
                    { required: true, validator: checkPhone, trigger: 'blur' }
                ],
                email: [
                    { required: true, message: '邮箱不能为空', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                    { pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,30}$/, message: '密码为数字，小写字母，大写字母，特殊符号 至少包含三种，长度为 8 - 30位，密码不能包含 用户名，公司名称(lidian), 公司域名(rekoon) （判断的时候不区分大小写)' }
                ],
                checkpassword: [
                    { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    { required: true, validator: checkPass, trigger: 'blur' }
                ],
            },
            ruleuser:{
                zhanghao: [
                    { required: true, message: '账号不能为空', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                ],
            }
        }
    },
    mounted(){
        var a=setInterval(()=>{
            this.boxopacity+=0.132;
            if(this.boxopacity>=1){  
                this.boxopacity=1;
                clearInterval(a)
            }
        }, 50);
        var b=setInterval(()=>{
            this.loading=false;
        }, 2000);
    },
    methods:{
        gologin(){
            var that=this;
            that.loginload=1;
            if(this.logincheck){
            
                that.$axios.get('https://hctoyc.com/bokephp/login.php',{params:{zhanghao:that.user.zhanghao,password:that.user.password}}).then(
                    (response)=>{
                        if(response.data==1){
                            this.$message({
                                message: '登陆成功',
                                type: 'success'
                            });
                            that.$axios.get('https://hctoyc.com/bokephp/infor.php',{params:{zhanghao:that.user.zhanghao}}).then(
                                (response)=>{
                                    that.$store.state.load=true;
                                    that.$store.state.user.nickname=response.data.nickname;
                                    that.$store.state.user.email=response.data.email;
                                    vmson.$emit('setimg', response.data.phone);
                                    that.loginload=0;
                                    that.closelogin();
                                },
                                (err)=>{

                                }
                            )
                        }else if(response.data==0){
                            this.$message.error('密码错误');
                            that.loginload=0;
                        }else if(response.data==3){
                            this.$message({
                                message: '没有此账号,或未注册',
                                type: 'warning'
                            });
                            that.loginload=0;
                        }
                    },
                    (err)=>{

                    }
                )
            }else{
                that.loginload=0;
                that.$message.error('请勾选协议');
            }
        },
        tologin(){
            this.$refs.zhuce.clearValidate();
            this.loginshow=true;
            this.loginshow=true;
            this.buzhou=1;
            this.xieyiopacity=1;
            this.nexttxt='下一步';
            this.zhuce.nickname='';
            this.zhuce.phone='';
            this.zhuce.email='';
            this.zhuce.password='';
            this.zhuce.checkpassword='';
            this.yzna=false;
            this.yzph=false;
            this.yzem=false;
            this.yzpa=false;
            this.zhucecheck=false;
        },
        tozhuce(){
            this.$refs.user.clearValidate();
            this.loginshow=false;
        },
        next(){
            var that=this;
            if(this.buzhou==3){
                this.loginshow=true;
                this.buzhou=1;
                this.xieyiopacity=1;
                this.nexttxt='下一步';
                this.tologin();
            }else if(this.buzhou==2){
                if(this.zhucecheck==true){
                    this.yzpass();
                    this.loginload=2;
                }else{
                    that.loginload=0;
                    this.$message.error('请勾选协议');
                }
            }else{
                this.loginload=2;
                this.yzphone();
            }
        },
        close(){
            var a=setInterval(()=>{
                this.boxopacity-=0.132;
                if(this.boxopacity<=0){  
                    this.boxopacity=0;
                    this.closelogin();
                    clearInterval(a)
                }
            }, 50);
            
        },
        yzname(){
            this.yzna=true;
        },
        yzphone(){
            var that=this;
            if(that.zhuce.phone!=''){
                that.$axios.get('https://hctoyc.com/bokephp/phone.php',{params:{phone:that.zhuce.phone}}).then(
                    (response)=>{
                        console.log(response);
                        if(response.data==0){
                            that.yzph=true;
                            that.yzemail();
                            that.yzname();
                        }else{
                            that.yzph=false;
                            that.$message({
                                message: '手机号已存在',
                                type: 'warning'
                            });
                        }
                    },
                    (err)=>{

                    }
                )
            }
        },
        yzemail(){
            var that=this;
            if(that.zhuce.phone!=''){
                that.$axios.get('https://hctoyc.com/bokephp/email.php',{params:{email:that.zhuce.email}}).then(
                    (response)=>{
                        console.log(response);
                        if(response.data==0){
                            that.yzem=true;
                        }else{
                            that.yzem=false;
                            this.$message({
                                message: '邮箱已存在',
                                type: 'warning'
                            });
                        }
                    },
                    (err)=>{

                    }
                )
            }else{// 差个空的提示还有格式错误的报错

            }
        },
        yzpass(){
            var that=this;
            if(that.zhuce.password!=''){
                if(that.zhuce.checkpassword!=''){
                    if(that.zhuce.password==that.zhuce.checkpassword){

                        that.$axios.get('https://hctoyc.com/bokephp/zhuce.php',{params:{nickname:that.zhuce.nickname,password:that.zhuce.password,phone:that.zhuce.phone,email:that.zhuce.email}}).then(
                            (response)=>{
                                console.log(response.data);
                                if(response.data==1){
                                    that.yzpa=true;
                                    that.$message({
                                        message: '注册成功',
                                        type: 'success'
                                    });
                                }else if(response.data==0){
                                    console.log('注册失败');
                                }
                            },
                            (err)=>{

                            }
                        )  
                    }else{
                        that.loginload=0;
                        that.$message.error('请确认密码输入保持一致');
                    }
                }else{
                    that.loginload=0;
                    that.$message.error('确认密码输入不能为空');
                }
            }else{
                that.loginload=0;
                that.$message.error('密码输入不能为空');
            }
        },
    },
    props:{
        showlogin:{
            type: Function,
            required: true, 
        },
        closelogin:{
            type: Function,
            required: true,
        },
    },
    watch:{
        loginload:{
            deep:true,
            immediate:true,
            handler(newName, oldName){
                if(newName==2){
                    var b=setInterval(()=>{
                        this.loginload=0;
                    }, 3000);
                }
            }
        },
        yzpa:{
            immediate: true,
            deep: true,
            handler(newName, oldName) {
                if(newName==true){
                    this.loginload=0;
                    console.log('昵称=>'+this.zhuce.nickname+',电话=>'+this.zhuce.phone+',邮箱=>'+this.zhuce.email+',密码=>'+this.zhuce.password);
                    this.nexttxt='完成';
                var a=setInterval(()=>{
                    this.inforleft-=26.6;
                    this.inforopacity-=0.067;
                    if(this.inforleft<=-400){
                        this.buzhou++;
                        this.inforleft=400;
                        this.inforopacity=0;
                        var b=setInterval(()=>{
                            this.inforleft-=26.6;
                            this.inforopacity+=0.067;
                            this.xieyiopacity-=0.067;
                            if(this.inforleft<=0){
                                this.inforleft=0;
                                this.inforopacity=1;
                                this.xieyiopacity=0;
                                clearInterval(b);
                            }
                        }, 50);
                        clearInterval(a);
                    }
                }, 50);
                }else{
                    this.loginload=0;
                }
            }
        },
        yzem:{
            immediate: true,
            deep: true,
            handler(newName, oldName) {
                if(newName==true){
                    this.loginload=0;
                    var a=setInterval(()=>{
                        this.inforleft-=26.6;
                        this.inforopacity-=0.067;
                        if(this.inforleft<=-400){
                            this.buzhou++;
                            this.inforleft=400;
                            this.inforopacity=0;
                            var b=setInterval(()=>{
                                this.inforleft-=26.6;
                                this.inforopacity+=0.067;
                                if(this.inforleft<=0){  
                                    this.inforleft=0;
                                    this.inforopacity=1;
                                    clearInterval(b);
                                }
                            }, 50);
                            clearInterval(a);
                        }
                    }, 50);
                }else{
                    this.loginload=0;
                }
            }
        }
    }
}
</script>

<style scoped>
.login-box{
    z-index: 50;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}
.nrbox{
    position: absolute;
    top: 150px;
    left: 260px;
    width: 1400px;
    height: 700px;
    border-radius: 25px;
    overflow: hidden;
}
.icon-close{
    position: absolute;
    z-index: 100;
    transition: all 0.3s;
    font-size: 46px;
    top: 65px;
    right: 260px;
    float: right;
}
.icon-close:hover{
    color: skyblue;
}
.sr{
    letter-spacing: 2px;
    width: 400px;
    margin: 10px 120px;
}
.login{
    letter-spacing: 1px;
    width: 700px;
    height: 540px;
    margin-top: 50px;
    margin-left: 350px;
    padding: 30px 50px;
}
.login-title{
    font-size: 26px;
    letter-spacing: 2px;
    width: 100%;
    height: 100px;
    text-align: center;
    line-height: 70px;
}
.login-name{
    width: 460px;
    height: 60px;
    margin: 15px 120px;
}
.infor-name{
    transition: all 0.3s;
    position: relative;
    width: 100%;
    height: 240px;
    overflow: hidden;
}
.cg-input{
    width: 400px;
    height: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.login-auto{
    width: 100%;
    height: 60px;
    line-height: 60px;
}
.login-check{
    vertical-align: middle;
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin: 20px 0;
}
.login-button{
    letter-spacing: 2px;
    width: 180px;
    height: 50px;
}
.zhuce{
    position: absolute;
    top: 0px;
    width: 700px;
    height: 540px;
    padding: 30px 50px;
    margin-top: 50px;
    margin-left: 350px;
    border-radius: 25px;
}
.buzhou-box{
    width: 100%;
    height: 100px;
}
.buzhou{
    width: 200px;
    height: 100px;
    display: inline-block;
    margin: 10px;
    transition: all 0.3s;
}
.buzhou div:nth-child(1){
    transition: all 0.3s;
    font-weight: 600;
    color: #dadada;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin: 0 80px;
}
.buzhou div:nth-child(2){
    transition: all 0.3s;
    color: #dadada;
    width: 100%;
    height: 30px;
    margin: 10px 0;
    text-align: center;
    line-height: 30px;
}
.zhuce-input{
    width: 400px;
    height: 60px;
    margin: 30px 120px;
}
.zhuce-check{
    width: 400px;
    height: 30px;
    margin: 30px 120px 10px;
    letter-spacing: 2px;
}
.zhuce-next{
    letter-spacing: 2px;
    width: 400px;
    height: 50px;
    margin: 10px 120px;
    font-size: 14px;
}
.zhuce-button{
    transition: all 0.3s;
    width: 460px;
    height: 50px;
}
.active{
    color: #398dee;
}
.activeborder{
    border: 2px solid #398dee;
}
.zhucefinish{
    width: 420px;
    height: 240px;
    margin: 0 120px;
    overflow: hidden;
}
.zhucefinish i{
    font-size: 100px;
    text-align: center;
}
.zhucefinish div:nth-child(1){
    color:#409eff;
    margin-top: 50px;
    width: 100%;
    text-align: center;
}
.zhucefinish div:nth-child(2){
    color:#409eff;
    width: 100%;
    margin-top: 10px;
    text-align: center;
}
</style>