import Vue from 'vue'
import Vuex from 'vuex'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
import store from './store/index.js'
import axios from 'axios'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.prototype.$axios = axios;

new Vue({
  render: h => h(App),
  store,
  router:router
}).$mount('#app')
