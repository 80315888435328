<template>
  <div class="look-box" :style="[{'opacity':opacity},{'left':'-'+left+'%'}]">
    <div class="look-top">
        <el-button type="primary" @click="close" style="position: absolute;left:0;margin: 10px;" icon="el-icon-back">返回</el-button>
        日记详情
    </div>
    <div class="look-nrbox">
        <div class="look-title">标题</div>
        <div class="look-time">编辑于:&nbsp;{{row.data}}&nbsp;&nbsp;{{row.times}}&nbsp;&nbsp;{{row.mood}}</div>
        <div class="look-nr">
            {{row.nr}}
        <br>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name:'Lookdiary',
    data(){
        return{
            opacity:0,
            left:100,
        }
    },
    mounted(){
        var a = setInterval(() => {
            this.opacity+=0.132;
            this.left-=13.2;
            if(this.opacity>=1){
                this.opacity=1;
                this.left=0;
                clearInterval(a);
            } 
        }, 50);
    },
    methods:{
        close(){
            var a = setInterval(() => {
            this.opacity-=0.132;
            this.left+=13.2;
            if(this.opacity<=0){
                this.opacity=0;
                this.left=100;
                this.closethis();
                clearInterval(a);
            } 
        }, 50);
            
        }
    },
    props:{
        closethis:{
            type: Function,
            required: true,
        },
        row:{
            type: Object,
            required: true,
        },
    },
    row:{
            deep:true,
            immediate:true,
            handler(newName, oldName) {}
        }
}
</script>

<style scoped>
.look-box{
    transition: all 0.2s;
    position: relative;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #c6e2ff;
}
.look-top{
    position: relative;
    width: 100%;
    height: 59px;
    border-bottom: 1px solid #dadada;
    text-align: center;
    font-size: 24px;
    line-height: 59px;
}
.look-nrbox{
    width: 100%;
    height: 817px;
    background-color: #fafafa;
}
.look-title{
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 46px;
    font-weight: 600;
    padding-top: 50px;
}
.look-time{
    width: 80%;
    height: 40px;
    line-height: 40px;
    margin: 0 10% 20px;
    border-bottom: 1px solid #dadada;
    
}
.look-nr{
    width: 90%;
    height: 626px;
    line-height: 40px;
    margin: 0 5%;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>