<template>
    <div class="notice-box">
        <div class="notice-left">
            <div class="bianji" v-if="this.$store.state.user.phone=='13530390221'">
                <el-button type="primary" @click="openwritenotice">发布新公告</el-button>
            </div>
            <div class="write-note" v-if="writeshow==true" :style="[{'opacity':opacity},{'left':'-'+left+'px'}]">
                <div class="write-top">
                    <el-button class="write-return" type="primary" icon="el-icon-arrow-left" @click="closewritenotice">关闭</el-button>
                    发布公告
                </div>
                <form action="notice" class="formbox">
                    <span class="fenge"></span>
                    <el-input placeholder="发布者称呼" v-model="author">
                        <template slot="prepend">发布者</template>
                    </el-input>
                    <span class="fenge"></span>
                    <el-input placeholder="请输入首标题" v-model="title">
                        <template slot="prepend">首标题</template>
                    </el-input>
                    <span class="fenge"></span>
                    <el-input type="textarea" :rows="12" placeholder="请输入更新内容" v-model="nr">
                    </el-input>
                </form>
                <el-button class="newnotice" type="primary" icon="el-icon-s-promotion" @click="cjnotice">发布</el-button>
            </div>
            <div class="title" ref="scorllview" @scroll="titlescorll">
                <div class="one-title" v-for="(item,index) in titleall" :key="index" @click="gundong(index)" :style="[{backgroundColor: gdindex == index ?'#409eff':'white'},{fontSize: gdindex == index ?'22px':'16px'}]">{{item.id}}</div>
                <div class="one-title-tc"></div>
                <div class="one-title-tc"></div>
            </div>
        </div>
        <div class="notice-right" ref="scorllviewnr">
            <el-timeline>
                <el-timeline-item :timestamp="item.data" placement="top" v-for="(item,index) in titleall" :key="index">
                    <el-card body-style="height:200px;">
                        <h4>{{item.title}}</h4>
                        <br>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.nr}}
                        <br>
                        <br>
                        {{item.author}} 提交于 {{item.data}} {{item.times}}
                        </p>
                    </el-card>
                </el-timeline-item>
                <div style="width:100%;height:574px;"></div>
            </el-timeline>
        </div>
    </div>
</template>

<script>
export default {
    name:'Notice',
    data(){
        return{
            opacity:0,
            left:'100',
            writeshow:false,
            gdindex:0,
            author:'',
            title:'',
            nr:'',
            titleall:[],
        }
    },
    mounted(){
        this.getnotice();
    },
    methods:{
        cjnotice(){
            var that=this;
            var data=that.getdaytime();
            var time=that.getmintime();
            that.$axios.get('https://hctoyc.com/bokephp/notice/createnotice.php',{params:{data:data,time:time,author:that.author,nr:that.nr,title:that.title}}).then(
                (response)=>{
                    that.getnotice();
                    that.$message({
                        message: '发布成功',
                        type: 'success'
                    });
                    that.closewritenotice();
                },
                (err)=>{
                    console.log(response.data);
                }
            )
        },
        getnotice(){
            var that=this;
            that.$axios.get('https://hctoyc.com/bokephp/notice/getnotice.php').then(
                (response)=>{
                    var dataContent = that.ReverseRankingDate(response.data, "id");
                    that.titleall=dataContent;
                },
                (err)=>{
                    console.log(response.data);
                }
            )
        },
        ReverseRankingDate(data, p) {
            for (var i = 0; i < data.length - 1; i++) {
                for (var j = 0; j < data.length - 1 - i; j++) {
                    if (Date.parse(data[j][p]) < Date.parse(data[j+1][p])) {
                        var temp = data[j];
                        data[j] = data[j + 1];
                        data[j + 1] = temp;
                    }
                }
            }
            return data;
        },
        getmintime(){
            var myDate = new Date();
            return myDate.toLocaleTimeString();
        },
         getdaytime(){
            var myDate = new Date();
            var date = new Date(myDate.toLocaleDateString())
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            return time;
        },
        gundong(index){
            this.gdindex=index;
            this.$refs.scorllview.scrollTop=index*40;
            this.$refs.scorllviewnr.scrollTop=index*287;
        },
        titlescorll(e){
            var num=e.srcElement.scrollTop;
            // this.gdindex=parseInt(num/=40)+1;
        },
        closewritenotice(){
            var a = setInterval(() => {
                this.opacity-=0.067;
                this.left+=6.67;
                if(this.opacity<=0){
                    this.opacity=0;
                    this.left=100;
                    this.writeshow=false;
                    clearInterval(a);
                } 
            }, 25);
            
        },
        openwritenotice(){
            this.writeshow=true;
            var a = setInterval(() => {
                this.opacity+=0.067;
                this.left-=6.67;
                if(this.opacity>=1){
                    this.opacity=1;
                    this.left=0;
                    
                    clearInterval(a);
                } 
            }, 25);
        }
    },
}
</script>

<style scoped>
html, body { scroll-behavior:smooth; }
.bgcolor{
    background-color: rgba(255,255,255,0.3);
}
.notice-box{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #fafafa;
}
.notice-left{
    position: relative;
    display: inline-block;
    width: 20%;
    height: 877px;
    overflow-x: hidden;
    overflow-y: scroll;
    
}
.notice-right{
    scroll-behavior:smooth;
    transition: all 0.3s;
    display: inline-block;
    width: 80%;
    height: 877px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.title{
    scroll-behavior: smooth; 
    transition: all 0.3s;
    width: 80%;
    height: 107px;
    margin: 185px 10%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 200px 0;
    border-radius: 15px;
    background-color: white;
    box-shadow: 5px 5px 20px 3px #ebebeb;
}
.bianji{
    width: 10%;
    position: absolute;
    top: 10%;
    left: 35%;
}
.one-title{
    width: 92%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    transition: all 0.3s;
    margin: 0 4%;
    padding: 2px 0;
    border-radius: 5px;
}
.one-title-tc{
    width: 92%;
    height: 36px;
    line-height: 36px;
    text-align: center;
    transition: all 0.3s;
    margin: 0 4%;
    padding: 2px 0;
    border-radius: 5px;
}
.one-title:hover{
    background-color: #409eff;
}
.write-note{
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
}
.write-top{
    padding: 10px;
    text-align: center;
    line-height: 40px;
}
.write-return {
    position: absolute;
    left: 10px;
}
.formbox{
    padding: 10px;
}
.newnotice{
    width: 30%;
    margin: 10px 35%;
}
.fenge{
    display: block;

    height: 20px;
}
</style>