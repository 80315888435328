<template>
  <div>
      后端还在学Java和PHP
  </div>
</template>

<script>
export default {
    name:'Hou',
    data(){
        return{

        }
    }
}
</script>

<style scoped>

</style>