// 专门用于创建应
import VueRouter from 'vue-router'
// 引入组件
import About from "../components/About";
import Hou from "../components/Hou";
import Login from "../components/Login";
import Note from "../components/Note";
import Notice from "../components/Notice";
import Person from "../components/Person";
import Qian from "../components/Qian";
import UserSet from "../components/UserSet";
import Diary from "../components/Diary";

export default new VueRouter({
    routes:[
		{
			path: '*',
			redirect: '/Qian',
		},
        {
			path:'/Qian',
			component:Qian,
		},
		{
			path:'/Hou',
			component:Hou,
		},
        {
			path:'/UserSet',
			component:UserSet,
		},
        {
			path:'/Note',
			component:Note,
		},
        {
			path:'/Notice',
			component:Notice,
		},
        {
			path:'/About',
			component:About,
		},
        {
			path:'/Person',
			component:Person,
		},
        {
			path:'/Login',
			component:Login,
		},
		{
			path:'/Diary',
			component:Diary,
		},
    ]
})