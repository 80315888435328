<template>
  <div class="set-box">
    <el-card class="nr-box">
        <div class="nr-title">账号设置</div>
        <el-form :model="set" ref="set" label-width="100px">
            <div class="upload">
                    <el-upload
                    class="avatar-uploader"
                    :action="uploadimg"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>
            <el-form-item label="昵称" style="font-weight:600;">
                <div class="base-infor" v-if="show.nickname==false">{{set.nickname}}</div>
                <el-input v-model="newinfor.nickname" class="change-input" v-if="show.nickname==true"></el-input>
                <el-button class="change-button" @click="show.nickname=!show.nickname" icon="el-icon-edit">编辑</el-button>
            </el-form-item>
            <el-form-item label="电话" style="font-weight:600;">
                <div class="base-infor" v-if="show.phone==false">{{set.phone}}</div>
                <el-input v-model="newinfor.phone" class="change-input" v-if="show.phone==true"></el-input>
                <el-button class="change-button" @click="show.phone=!show.phone" icon="el-icon-edit">编辑</el-button>
            </el-form-item>
            <el-form-item label="邮箱" style="font-weight:600;">
                <div class="base-infor" v-if="show.email==false">{{set.email}}</div>
                <el-input v-model="newinfor.email" class="change-input" v-if="show.email==true"></el-input>
                <el-button class="change-button" @click="show.email=!show.email" icon="el-icon-edit">编辑</el-button>
            </el-form-item>
        </el-form>
    </el-card>
  </div>
</template>

<script>
import vmson from "./emptyVue"
export default {
    name:'UserSet',
    data(){
        return{
            imageUrl: 'https://hctoyc.com/bokephp/touxiang/'+this.$store.state.user.phone+'.jpg?res='+new Date().getTime(),
            uploadimg:'https://hctoyc.com/bokephp/imgurl.php?phone='+this.$store.state.user.phone,
            set:{
                nickname:this.$store.state.user.nickname,
                phone:this.$store.state.user.phone,
                email:this.$store.state.user.email,
            },
            newinfor:{//每次确认修改都要进行覆盖
                nickname:this.$store.state.user.nickname,
                phone:this.$store.state.user.phone,
                email:this.$store.state.user.email,
            },
            show:{
                nickname:false,
                phone:false,
                email:false,
            }
        }
    },
    methods:{
        handleAvatarSuccess(res, file) {
            if(res==this.$store.state.user.phone){
                this.imageUrl = 'https://hctoyc.com/bokephp/touxiang/'+this.$store.state.user.phone+'.jpg?res='+new Date().getTime();
                // this.$store.dispatch('changeimg',this.$store.state.user.phone);
                vmson.$emit('setimg', this.$store.state.user.phone)
                this.$message({
                    type: 'success',
                    message: '修改头像成功!'
                });
            }
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    },
    watch:{
        imageUrl:{
            deep:true,
            immediate:true,
            handler(newName,oldName){
                this.imageUrl = 'https://hctoyc.com/bokephp/touxiang/'+this.$store.state.user.phone+'.jpg?res='+new Date().getTime();
                this.$store.state.user.img=this.imageUrl;
            }
        }
    }
}
</script>

<style scoped>
.set-box{
    width: 100%;
    height: 100%;
    background-color: skyblue;
}
.nr-box{
    position: relative;
    top: 50px;
    width: 50%;
    margin: auto;
    border-radius: 25px;
    background-color: white;
}
.nr-title{
    font-size: 30px;
    margin-bottom: 60px;
    padding-top: 50px;
}
.base-infor{
    display: inline-block;
    width: 400px;
    height: 40px;
    line-height: 40px;
    font-weight:400;
}
.change-input{
    width: 400px;
    height: 30px;
}

.avatar-uploader .el-upload {
    border: 1px dashed black;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    left: 70px;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    display: block;
  }
  .upload{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: skyblue;
    overflow: hidden;
    border: 2px solid skyblue;
    position: relative;
    left: 60px ;
    margin-bottom: 20px;
  }
</style>