<template>
  <div class="boxz">
      关于本站
  </div>
</template>

<script>
export default {
    name:'About',
    data(){
        return{

        }
    }
}
</script>

<style scoped>
.boxz{
    width: 200px;
    height: 300px;
    background-color: red;
}
</style>