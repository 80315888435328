<template>
    <div class="look-box" :style="[{'opacity':opacity},{'top':top+'%'}]">
        <div class="look-top">
            <el-button type="primary" @click="close" style="position: absolute;left:0;margin: 10px;"
                icon="el-icon-back">返回</el-button>
            <el-button type="primary" @click="preservation" style="position: absolute;left:111px;margin: 10px;"
                icon="el-icon-document-checked">保存</el-button>
            记录点点滴滴
        </div>
        <div class="look-nrbox">
            <div class="look-title">
                <span style="display:inline-block;width:90%;height:100%;" v-if="srtitleshow==false" @click="srtitleshow=true">{{titles}}</span>
                <input type="text" class="look-srtitle" v-if="srtitleshow==true" @change="srtitleshow=false"
                    v-model="titles">
            </div>
            <div class="look-time">当前时间:&nbsp;<el-date-picker v-model="writedata" type="date" placeholder="选择日期">
                </el-date-picker>&nbsp;&nbsp;&nbsp;
                心情:&nbsp;<el-input class="mood-sr" placeholder="今天是什么心情呢" v-model="moods">
                <i slot="suffix" class="el-icon-lollipop"></i></el-input>
            </div>
            <div class="look-nr">
                <el-input type="textarea" :rows="25" placeholder="请输入内容" v-model="textarea" @change="shuchu">
                </el-input>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'Writediary',
    data(){
        return{
            opacity:0,
            top:100,
            srtitleshow:false,
            titles:'标题',
            writedata:'',
            usedata:'',
            moods:'',
            textarea:'',
            safe:true,//修改状态
        }
    },
    mounted(){
        var a = setInterval(() => {
            this.opacity+=0.132;
            this.top-=13.2;
            if(this.opacity>=1){
                this.opacity=1;
                this.top=0;
                clearInterval(a);
            } 
        }, 50);
    },
    methods:{
        shuchu(){
            console.log(this.textarea);
        },
        close(){
            var a = setInterval(() => {
                this.opacity-=0.132;
                this.top+=13.2;
                if(this.opacity<=0){
                    this.opacity=0;
                    this.top=100;
                    this.closewrite(); 
                    clearInterval(a);
                } 
            }, 50);
        },
        preservation(){
            var that=this;
            if(that.usedata!='NaN-NaN-NaN'){
                var daytime=that.usedata;
            }else if(that.usedata=='NaN-NaN-NaN'){
                var daytime=that.getdaytime();
            }
            let mintime=that.getmintime();
            var phone=that.$store.state.user.phone;
            let nr=that.textarea;
            let mood=that.moods;
            let title=that.titles;
            that.$axios.get('https://hctoyc.com/bokephp/diary/newdiary.php',{params:{daytime:daytime,mintime:mintime,phone:phone,nr:nr,mood:mood,title:title}}).then(
                (response)=>{
                    that.getdiary();
                },
                (err)=>{
                }
            )



            this.$message({
                message: '保存成功',
                type: 'success'
            });
        },
        getdaytime(){
            var myDate = new Date();
            var date = new Date(myDate.toLocaleDateString())
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            return time;
        },
        zhuanhuan(newName){
            var date = new Date(newName)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            return time;
        },
        getmintime(){
            var myDate = new Date();
            return myDate.toLocaleTimeString();
        },
    },
    watch:{
        writedata:{
            deep:true,
            immediate:true,
            handler(newName,oldName){
                let time=this.zhuanhuan(newName);
                this.usedata=time;
            }
        }
    },
    props:{
        closewrite:{
            type: Function,
            required: true,
        },
        getdiary:{
            type: Function,
            required: true,
        },
    },
}
</script>

<style scoped>
.look-box{
    transition: all 0.2s;
    position: relative;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: #c6e2ff;
}
.look-top{
    position: relative;
    width: 100%;
    height: 59px;
    border-bottom: 1px solid #dadada;
    text-align: center;
    font-size: 24px;
    line-height: 59px;
}
.look-nrbox{
    width: 100%;
    height: 817px;
    background-color: #fafafa;
    overflow-x: hidden;
    overflow-y: scroll;
}
.look-title{
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 46px;
    font-weight: 600;
    padding: 50px 0 20px;
}
.look-srtitle{
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 46px;
    font-weight: 600;
    border: none;
    outline: none;
}
.look-time{
    letter-spacing: 2px;
    width: 80%;
    height: 40px;
    line-height: 40px;
    margin: 0 10% 20px;
    border-bottom: 1px solid #dadada;
    
}
.look-nr{
    width: 90%;
    height: 606px;
    line-height: 40px;
    margin: 0 5%;
    overflow-y: scroll;
    overflow-x: hidden;
}
.choose-time{
    display: inline-block;
    width: 100px;
    height: 40px;
}
.mood-sr{
    width: 200px;
    height: 40px;
}
</style>