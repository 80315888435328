<template>
    <div class="Diary-box">
        <div class="czdiary" v-if="existencediary==true">
            <el-drawer ref="zhezhao" title="选择日期" :visible.sync="drawer" :direction="direction"
                :before-close="handleClose">
                <el-calendar v-model="value"></el-calendar>
                <el-button type="primary" style="margin:0 35%;" @click="lookall">查看全部</el-button>
            </el-drawer>
            <div class="diary-left">
                <div class="diary-left-top">
                    <div class="left-top-title">当前日期:&nbsp;&nbsp;&nbsp;{{dqchoosedata}}</div>
                    <el-button @click="drawer = true" type="primary" style="margin: 10px;" icon="el-icon-date">日期选择
                    </el-button>
                </div>
                <div class="diary-top-nr">
                    <el-table :data="diarydata" height="817" stripe style="width: 100%;margin-bottom: 20px;"
                        row-key="id" border default-expand-all @row-click="lookthis"
                        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                        <el-table-column prop="data" label="日期" sortable width="120">
                        </el-table-column>
                        <el-table-column prop="times" label="时间" width="100">
                        </el-table-column>
                        <el-table-column prop="title" label="标题" width="150">
                        </el-table-column>
                        <el-table-column prop="mood" label="心情" :formatter="stateFormat">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="diary-right">
                <div class="diary-left-top">
                    <el-button type="primary" @click="creatediary" style="margin: 10px;" icon="el-icon-edit">写日记
                    </el-button>
                </div>
                <el-empty class="nodiary" description="想写点什么呢"></el-empty>
                <Lookdiary ref="look" v-if="lookshow==true" :closethis="closethis" :row="row"></Lookdiary>
                <Writediary ref="write" v-if="writeshow==true" :closewrite="closewrite" :getdiary="getdiary">
                </Writediary>
            </div>
        </div>
        <div class="nodiary" v-if="existencediary==false">
            <el-empty description="您还没有创建日记库"></el-empty>
            <div style="margin:auto;width: 120px;">
                <el-button type="primary" @click="creatediaryku">创建日记库</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Lookdiary from './Lookdiary.vue'
import Writediary from './Writediary.vue'
export default {
    name:'Diary',
    components:{
        Lookdiary,Writediary
    },
    data(){
        return{
            existencediary:false,
            drawer: false,
            lookshow:false,
            writeshow:false,
            direction: 'ltr',
            value:'',
            dqchoosedata:'',
            diarydata:[],
            row:{},
        };
    },
    mounted(){
        this.chaxun();
    },
    methods:{
        lookall(){
            this.dqchoosedata='全部';
            this.getdiary();
            this.$refs.zhezhao.handleClose();
        },
        lookthis(row,column,event){
            if(this.writeshow==true){
                this.$refs.write.close();
            }
            if(this.lookshow==true){
                this.$refs.look.close();
            }
            let i=0;
            var a = setInterval(() => {
                i++;
                if(i==15){
                    this.row=row;
                    this.lookshow=true;
                    clearInterval(a);
                }
            }, 50);
        },
        closethis(){
            this.lookshow=false;
        },
        closewrite(){
            this.writeshow=false;
        },
        creatediary(){
            this.writeshow=true;
        },
        getdaytime(){
            var myDate = new Date();
            var date = new Date(myDate.toLocaleDateString())
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            return time;
        },
        getmintime(){
            var myDate = new Date();
            return myDate.toLocaleTimeString();
        },
        handleClose(done) {
            done();
        },
        creatediaryku(){
            var that=this;
            that.$axios.get('https://hctoyc.com/bokephp/diary/creatediary.php',{params:{phone:that.$store.state.user.phone}}).then(
                (response)=>{
                    console.log(response.data);
                    if(response.data==1){
                        that.$message({
                            message: '创建成功',
                            type: 'success'
                        });
                        that.existencediary=true;
                    }else{
                        that.$message.error('创建失败');
                    }
                },
                (err)=>{

                }
            )
        },
        chaxun(){
            var that=this;
            that.$axios.get('https://hctoyc.com/bokephp/diary/searchdiary.php',{params:{phone:that.$store.state.user.phone}}).then(
                (response)=>{
                    if(response.data==0){
                        that.existencediary=true;
                        that.getdiary();
                    }else{
                        that.$message('还未创建日记库');
                    }
                },
                (err)=>{

                }
            )
        },
        getdiary(){
            var that=this;
            that.$axios.get('https://hctoyc.com/bokephp/diary/getdiary.php',{params:{phone:that.$store.state.user.phone}}).then(
                (response)=>{
                    var dataContent = that.ReverseRankingDate(response.data, "id");
                    that.diarydata=dataContent;
                },
                (err)=>{

                }
            )
        },
        shaixuandiary(ti){
            var that=this;
            that.$axios.get('https://hctoyc.com/bokephp/diary/getdiary.php',{params:{phone:that.$store.state.user.phone}}).then(
                (response)=>{
                    var dqdata=response.data;
                    var dq=[];
                    for(var i=0;i<dqdata.length;i++){
                        if(dqdata[i].data==ti){
                            dq.push(dqdata[i]);
                        }
                    }
                    if(dq!=''){
                        var dataContent = that.ReverseRankingDate(dq, "id");
                        that.diarydata=dataContent;
                    }else{
                        that.diarydata=[];
                    }
                },
                (err)=>{

                }
            )
        },
        ReverseRankingDate(data, p) {
            for (var i = 0; i < data.length - 1; i++) {
                for (var j = 0; j < data.length - 1 - i; j++) {
                    if (Date.parse(data[j][p]) < Date.parse(data[j+1][p])) {
                        var temp = data[j];
                        data[j] = data[j + 1];
                        data[j + 1] = temp;
                    }
                }
            }
            return data;
        },
        zhuanhuan(newName){
            var date = new Date(newName)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            const time = y + '-' + m + '-' + d
            return time;
        },
        // 格式化表格消息内容
        stateFormat(row, column, cellValue) {
            // console.log(row , column , cellValue)
            if (!cellValue) return "";
            if (cellValue.length > 12) {
                //最长固定显示4个字符
                return cellValue.slice(0, 12) + "...";
            }
            return cellValue;
        },
    },
    computed:{},
    watch:{
        value:{
            deep:true,
            immediate:true,
            handler(newName,oldName){
                let time=this.zhuanhuan(newName);
                if(time=='NaN-NaN-NaN'){
                    this.dqchoosedata='全部';
                    this.getdiary();
                }else{
                    this.dqchoosedata=time;
                    this.shaixuandiary(time);
                    this.$refs.zhezhao.handleClose();
                }
                
            }
        },
    }
}
</script>

<style scoped>
.Diary-box{
    width: 100%;
    height: 100%;
    background-color: white;
}
.czdiary{
    width: 100%;
    height: 100%;
}
.nodiary{
    position: relative;
    top: 200px;
}
.diary-left{
    width: 30%;
    height: 837px;
    overflow-y: hidden;
    overflow-x: hidden;
    float: left;
}
.diary-right{
    position: relative;
    width: 70%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    float: right;
}
.diary-left-top{
    width: 100%;
    height: 59px;
    background-color: #c6e2ff;
    border-bottom: 1px solid #dadada;
}
.left-top-title{
    width: 200px;
    letter-spacing: 1px;
    display: inline-block;
}
</style>